'use client'

import { usePathname } from 'lib/i18n/routing'
import { useEffect } from 'react'

/**
 * Logs a Posthog event when a page renders English content with a different locale set
 */
export const EnFallbackEventLogger = ({ locale }: { locale: string }) => {
	const pathname = usePathname()

	useEffect(() => {
		// Don't log if the locale is English
		if (locale === 'en') {
			return
		}

		;(async () => {
			try {
				await fetch('/api/log-en-fallback-to-posthog', {
					method: 'POST',
					body: JSON.stringify({ locale, pathname }),
				})
			} catch (error) {
				console.warn('Error logging event in PostHog', error)
			}
		})()
	}, [])

	return <></>
}
